<template>
  <div id="app">
    <div class="imageView">
      <div class="imageViewMargin">
        <img id="firstPicture" :src="obj.firstPicture" alt />
      </div>
      <!-- -->
      <img v-if='status == 2'    src="../../assets/status.png" class="status" alt />
      <!-- 文字   -->
      <div>
        <div class="wrapperFlex">
          <div class="wrapperFlexLeft">
            <div>姓</div>
            <div>名 ：</div>
          </div>
          <div id="userName" class="wrapperRight">{{ obj.userName || "" }}</div>
        </div>
        <div class="wrapperFlex">
          <div class="wrapperFlexLeft">
            <div>岗</div>
            <div>位 ：</div>
          </div>
          <div id="station" class="wrapperRight">{{ obj.station || "" }}</div>
        </div>
        <div class="wrapperFlex">
          <div class="wrapperFlexLeft">
            <div>岗</div>
            <div>位</div>
            <div>级</div>
            <div>别 ：</div>
          </div>
          <div id="stationLevel" class="wrapperRight">{{ obj.stationLevel || "" }}</div>
        </div>
        <div class="wrapperFlex">
          <div class="wrapperFlexLeft">
            <div>证</div>
            <div>书</div>
            <div>编</div>
            <div>号 ：</div>
          </div>
          <div id="certificateNo" class="wrapperRight">{{ obj.certificateNo || "" }}</div>
        </div>
      </div>
      <div
        style="
          box-sizing: border-box;
          padding: 0 24px 0 36px;
          overflow: hidden;
          position: relative;
          margin-top: 18.2px;
        "
      >
        <div class="wrapperErWei">
          <img id="secondPicture" :src="obj.secondPicture" alt />
        </div>
        <div class="wrapperErWeiRight">
          <div class="wrapperErWeiRightFlex" style="margin-bottom: 3px">
            <div>发证单位 ：</div>
            <div>中国汽车流通协会</div>
          </div>
          <div class="wrapperErWeiRightFlex">
            <div>发证日期 ：</div>
            <div id="ateIssueTime">{{ obj.ateIssueTime || "" }}</div>
          </div>
        </div>
      </div>
    </div>
    <img
      class="image"
      src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation/job_cert_reverse_20250121.jpg"
      alt
    />
    <img class="back_img" @click="call_back" src="../../assets/backs.png" alt="">
  </div>
</template>

<script>
import { certificateMessAge } from "@/utils/http.js";
export default {
  // 组件名称
  name: "demo",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      obj: {
        userName: "张三",
        station: null,
        stationLevel: "岗位级别/中级",
        certificateNo: "",
        firstPicture: "",
        secondPicture: "",
        ateIssueTime: undefined,
      },
      status:1,
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    call_back() { this.$router.go(-1); },
    async getList() {
      // var certId = 580;
      var certId = this.$route.query.certId;
      const { data: res } = await certificateMessAge({ certId });
      this.obj = res.content;
      console.log(res.content)
      this.status = res.content.status
    },
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 在实例初始化之后，组件属性计算之前，如data属性等
   */
  beforeCreate() {},
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {},
  /**
   * 在挂载开始之前被调用：相关的 render 函数首次被调用。
   */
  beforeMount() {},
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {
    let list = {
      a: 1,
      b: 2,
      c: 3,
      list: {
        a: 1,
        b: 2,
        c: 3,
      },
    };
    // let { list } = list;
    // console.log(list, "===========");
    this.getList();
  },
  /**
   * 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。
   * 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
   */
  beforeUpdate() {},
  /**
   * 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
   * 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
   */
  updated() {},
  /**
   * keep-alive 组件激活时调用。 仅针对keep-alive 组件有效
   */
  activated() {},
  /**
   * keep-alive 组件停用时调用。 仅针对keep-alive 组件有效
   */
  deactivated() {},
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {},
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {},
};
</script> 

<style scoped>
body {
  width: 100%;
  background: #f8f8f8;
}

.image {
  /* width: 7.5rem; */
  width: 100%;
  height: 593px;
}

.imageView {
  margin-bottom: 10px;
  width: 100%;
  height: 593px;
  background: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation/job_cert_font_20250121.jpg")
    center center no-repeat;
  background-size: 100%;
  overflow: hidden;
}

.imageViewMargin {
  width: 146px;
  height: 204px;
  margin: 140px auto 20px;
}

.imageViewMargin > img {
  width: 100%;
  height: 100%;
}

.wrapperFlex {
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding: 5px 0;
}

.wrapperFlexLeft {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #241915;
  width: 95px;
}

.wrapperRight {
  flex: 1;
  font-size: 15px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #241915;
}

.wrapperErWei {
  width: 76px;
  height: 76px;
  float: left;
}

.wrapperErWei > img {
  width: 100%;
  height: 100%;
}

.wrapperErWeiRight {
  font-size: 13px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #241915;
  position: absolute;
  bottom: 0;
  right: 24px;
}

.wrapperErWeiRightFlex {
  display: flex;
}
.status {
  width: 119px;
  height: 80px;
  position: absolute;
  top: 228px;
  right: 58px;
}
.back_img {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 150px;
  z-index: 99999999;
}
</style>
