<template>
  <div class="container">
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <div class="title">{{ item.title }} <span v-if="!$route.query.id">({{ item.imgList.length }})</span></div>
        <ul class="img-list">
          <li v-for="(ite, ind) in item.imgList" :key="ind" class="list-item">
            <!-- <img :src="ite" alt @click="callBack(index,ind)" /> -->
            <van-image
              v-if="ite.valueType != 1"
              fit="cover"
              :src="ite.value"
              @click="callBack(ite)"
            />
            <van-image
              v-else
              fit="cover"
              :src="ite.poster"
              @click="callBack(ite)"
            />
            <div class="imgName">{{ ite.name }}</div>
            <div class="play" v-if="ite.valueType == 1" @click="callBack(ite)">
              <img src="../../assets/newReport/play.png" alt />
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <img
      class="back_img"
      @click="call_back"
      src="../../assets/backs.png"
      alt=""
    />
  </div>
</template>
<script>
import { report, reportImg } from "@/utils/http.js";
export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.list = this.$store.state.imgList;
  },
  methods: {
    call_back() {
      this.$router.go(-1);
    },
    callBack(ite) {
      console.log(ite);
      let data = { id: ite.index, name: ite.value };
      this.$store.commit("setImgKey", data);
      this.$router.go(-1);
    },
  },
};
</script>
<style lang='less' scoped>
.container {
  min-height: 100vh;
  background: #333333;
  padding-bottom: 20px;
  color: #ffffff;
}
.title {
  height: 40px;
  line-height: 40px;
  text-indent: 20px;
  font-size: 16px;
  /* margin-top: 20px; */
}
/* .title:nth-of-type(1) {
  margin: 0;
} */
.img-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 10px;
}

.list-item {
  width: 109px;
  height: 82px;
  margin-top: 5px;
  margin-left: 5px;
  position: relative;
}

.play {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  left: 40px;
}
.play > img {
  width: 100%;
  height: 100%;
}
.imgName {
  width: 100%;
  height: 23px;
  position: absolute;
  bottom: 0;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 23px;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0 10px;
  // background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #000000 100%);
  border-radius: 0px 0px 5px 5px;
  background-image: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/gradient_background.png");
  background-size: 100% 100%;
}

.list-item > .van-image,
.list-item > video {
  width: 100%;
  height: 100%;
}
/deep/ .list-item > .van-image > img {
  border-radius: 5px;
}
.back_img {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 150px;
  z-index: 99999999;
}
</style>