<template>
    <div class="container">
      <van-tabs v-model="actives" scrollspy sticky title-active-color="#0266E6">
        <van-tab v-for="(item,index) in port.checkItems" :title="item.shortName" :key="index">
          <div class="content">
            <div class="name">{{item.title}}</div>
            <ul class="changeNumber car-name c-p-n">
              <li>
                <div>{{item.impairment}}</div>
                <div v-if=" item.title !='上装检查项目'">合计减值</div>
                <div v-if=" item.title =='上装检查项目'">价值分数</div>
              </li>
              <li class="lines"></li>
              <li>
                <div>{{item.level}}级</div>
                <div>评价等级</div>
              </li>
              <li class="lines"></li>
              <li v-if="item.coefficient">
                <div>{{item.coefficient}}</div>
                <div>骨架系数</div>
              </li>
              <li v-if="item.score">
                <div>{{item.score}}</div>
                <div>鉴定分值</div>
              </li>
              <li v-if="item.score == 0">
                <div>{{item.score}}</div>
                <div>鉴定分值</div>
              </li>
            </ul>
            <ul class="nav">
              <li class="nav-item">
                <!-- <div class="nav-title">{{item.title}}</div> -->
                <!-- <img :src="ite.img" alt /> -->
                <ul class="it-list">
                  <li class="item-list" v-for="(it,ind) in item.children" :key="ind">
                    <div v-if="it.label">{{it.index}}. {{it.label}}</div>
                    <img v-if="it.score == '0'" src="../../assets/report/success.png" alt />
                    <div v-if="it.score>0" @click="showInfo(it.index)">
                      <div>{{it.value}}</div>
                      <van-icon name="arrow" />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </van-tab>
      </van-tabs>
      <van-popup
        v-model="show"
        round
        lock-scroll
        closeable
        position="bottom"
        :style="{ height: '80%' }"
      >
        <div class="nav-titles">检查项目</div>
        <div class="box">
          <van-tabs
            :ellipsis="false"
            title-style="nav-title"
            title-active-color="#0266E6"
            v-model="active"
            sticky
            swipeable
            @click="onClick"
          >
            <van-tab
              v-for="(item,index) in port.images"
              :title="item.title+'('+item.children.length+')'"
              :key="index"
            >
              <div class="txt">
                <swiper
                  :options="swiperOption"
                  ref="swiper"
                  @touchMove="onTouchMoves"
                  @touchStart="onTouchStarts"
                  @touchEnd="onTouchEnds(item)"
                >
                  <swiper-slide v-for="(ite,ind) in item.children" :key="ind">
                    <div class="center">
                      <!-- <img :src="ite.image" alt /> -->
                      <van-image
                        fit="cover"
                        @click="onImageClick(item.children,ind)"
                        :src="ite.image"
                      />
                      <ul class="problem">
                        <li>{{ite.index}}. {{ite.label}}</li>
                      </ul>
                      <ul class="problem problems">
                        <li v-if="ite.code">代码：{{ite.code}}</li>
                        <li v-if="!ite.code">代码：-</li>
                        <li>减分：{{ite.score}}分</li>
                      </ul>
                      <div class="tips">{{ite.desc}}</div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </van-popup>
    </div>
  </template>
  <script>
  import { swiper, swiperSlide } from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
  import { ImagePreview } from "vant";
  export default {
    name: "",
    components: {
      swiper,
      swiperSlide,
    },
    props: [],
    data() {
      return {
        actives: 0,
        startX: null,
        startXs: null,
        surrentStartX: null,
        surrentStartXs: null,
        nextPage: 1,
        swiperOption: {
          loop: false,
          slideToClickedSlide: true,
          // 显示分页
          pagination: {
            // el: ".swiper-pagination",
            // clickable: true //允许分页点击跳转
          },
        },
        show: false,
        current: 1,
        active: 1,
        port: "",
      };
    },
    computed: {
      swiper() {
        //    thiswx.nextTick(() => {
        return this.$refs.mySwiper.swiper;
        //   });
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.port = this.$store.state.report;
      console.log(this.port.checkItems)
      console.log(this.$route.query.id);
      if (this.$route.query.id) {
        this.$nextTick(() => {
          this.actives = this.$route.query.id;
          console.log(this.actives, this.$route.query.id);
        });
      }
    },
    methods: {
      // 点击图片
      onImageClick(list, index) {
        console.log(list);
        let arr = [];
        list.forEach((item) => {
          arr.push(item.image);
        });
        ImagePreview({
          images: arr,
          startPosition: index,
        });
      },
      showInfo(id) {
        this.show = true;
        let arr = this.port.images;
        let info = "";
        let pageSize = "";
        this.port.images.forEach((item) => {
          for (let index = 0; index < item.children.length; index++) {
            if (item.children[index].index == id) {
              pageSize = index;
              info = item.children[index];
            }
          }
        });
        for (let index = 0; index < arr.length; index++) {
          arr[index].children.filter((item) => {
            if (item == info) {
              this.active = index;
            }
          });
        }
        this.$nextTick(() => {
          this.$refs.swiper[0].swiper.slideTo(pageSize, 500, false);
          console.log(this.$refs.swiper);
        });
      },
      onClick(name, title) {
        this.current = 1;
        this.nextPage = 1;
      },
      // 外部
      onTouchStarts(e) {
        e = e || event;
        if (e.touches.length == 1) {
          this.startXs = e.touches[0].clientX;
        }
      },
      onTouchMoves(e) {
        e = e || event;
        if (e.touches.length == 1) {
          this.surrentStartXs = e.touches[0].clientX;
        }
      },
      onTouchEnds(data) {
        console.log(data);
        this.$nextTick(() => {
          this.current = this.nextPage;
          if (this.surrentStartXs < this.startXs) {
            this.nextPage++;
            this.current++;
            if (this.nextPage > data.children.length) {
              // this.active++;
              // this.$refs.mySwiper[0].swiper.slideTo(0, 500, false);
              // this.current = 1;
              // this.nextPage = 1;
              // if (this.active > this.list.length - 1) {
              //   this.active = this.list.length - 1;
              this.current = data.children.length;
              this.nextPage = data.children.length;
              // }
            }
          }
          if (this.surrentStartXs > this.startXs) {
            this.nextPage--;
            this.current--;
            if (this.current == 0) {
              //   this.active--;
              //   if (this.active == -1) {
              //     this.current = 1;
              //     this.nextPage = 1;
              //   } else {
              //     this.$refs.mySwiper[0].swiper.slideTo(1, 500, false);
              this.current = 1;
              this.nextPage = 1;
              //   }
            }
          }
        });
      },
    },
  };
  </script>
  <style lang='less' scoped>
  .container {
    background: #f8f8f8;
    font-family: PingFangSC-Medium, PingFang SC;
    padding-bottom: 20px;
  }
  .content {
    width: 95%;
    margin: 0 auto;
    background: #ffffff;
    margin-top: 10px;
    box-sizing: border-box;
    padding: 0 12px;
  }
  .name {
    font-size: 19px;
    padding-top: 9px;
  }
  .changeNumber {
    display: flex;
    color: #666666;
    font-size: 13px;
    font-weight: 400;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    margin-top: 10px;
    justify-content: space-around;
  }
  .changeNumber > li {
    width: 108px;
    text-align: center;
  }
  .changeNumber > li > div:nth-of-type(1) {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .changeNumber > li {
    width: 108px;
    text-align: center;
  }
  .changeNumber > .lines {
    width: 0px;
    height: 35px;
    border-left: 1px solid #e6e6e6;
  }
  .c-p-n {
    /* width: 100%; */
    height: 80px;
    background-image: url("../../assets/report/number.png");
    background-size: 100% 100%;
    margin-top: 10px;
  }
  .c-p-n > li > div:nth-of-type(1) {
    color: #ffffff;
    margin-bottom: 10px;
  }
  .c-p-n > .lines {
    border-left: 1px solid #505050;
  }
  .nav-title {
    font-size: 17px;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
    margin-top: 10px;
  }
  .nav-item > img {
    width: 334px;
    height: 180px;
    border-radius: 5px;
  }
  /deep/ .van-tabs__line {
    width: 20px !important;
    height: 10px !important;
    background-color: transparent !important;
    background: url("https://ftp.bmp.ovh/imgs/2021/05/503330710675b702.png")
      center center;
    background-size: 100% 100%;
  }
  /deep/ .van-tab {
    font-size: 18px;
  }
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 50px;
  }
  .it-list {
    padding-bottom: 10px;
  }
  .item-list {
    display: flex;
    justify-content: space-between;
    min-height: 54px;
    align-items: center;
    font-size: 16px;
  }
  .item-list > img {
    width: 16px;
    height: 16px;
  }
  .item-list > div:nth-of-type(2) {
    display: flex;
    color: #999999;
  }
  .item-list > div:nth-of-type(1) {
    width: 200px;
    word-break: break-all;
    word-wrap: break-word;
    line-height: 20px;
    // display: flex;
    // color: #999999;
  }
  .item-list > div:nth-of-type(2) > div {
    margin-right: 10px;
    font-size: 14px;
    color: #999999;
  }
  .center {
    width: 345px;
    margin: 0 auto;
    padding-top: 10px;
    color: #333333;
    padding-bottom: 40px;
  }
  /deep/ .center > .van-image {
    width: 345px;
    height: 259px;
    margin: 0 auto;
  }
  /deep/ .center > .van-image {
    width: 345px;
    height: 259px;
    border-radius: 5px;
  }
  .money {
    height: 20px;
    line-height: 20px;
    font-size: 14px !important;
  }
  .tips {
    font-size: 14px;
    color: #d0d0d0;
    margin-top: 15px;
    line-height: 18px;
  }
  .nav-titles {
    text-align: center;
    font-size: 20px;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #f3f3f3;
  }
  /deep/ .van-popup__close-icon--top-right {
    top: 20px;
    right: 20px;
  }
  .problem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin-top: 10px;
    font-size: 18px;
  }
  .problems {
    justify-content: start;
    font-size: 15px;
  }
  .problems > li:nth-of-type(2) {
    margin-left: 10px;
  }
  .problem > li:nth-of-type(2) {
    // margin-left: 10px;
    font-size: 14px;
  }
  /deep/ .van-tab {
    font-size: 16px;
  }
  /deep/.van-tab--active > .van-tab__text {
    font-size: 19px;
  }
  </style>
  