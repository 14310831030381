<template>
  <!-- :='scro' -->
  <div class="container">
    <van-tabs :ellipsis="false" v-model="index" scrollspy sticky title-active-color="#0266E6">
      <van-tab class="tabs" v-for="(item, index) in port" :title="item.name" :key="index">
        <div ref="tab" class="content">
          <div class="name">{{ item.name }}({{ item.children.length }}项)</div>
          <ul class="changeNumber car-name c-p-n">
            <li>
              <div>{{ item.fullScore }}</div>
              <div style="font-size:#c9c9c9">满分</div>
            </li>
            <li class="lines"></li>
            <li>
              <div>{{ item.level }}</div>
              <div>评价等级</div>
            </li>
            <li class="lines"></li>
            <li>
              <div>{{ item.score }}</div>
              <!-- <div v-if="item.name == '骨架鉴定项目'">骨架系数</div> -->
              <div>{{ item.valueTitle }}</div>
            </li>
          </ul>
          <ul class="nav">
            <li class="nav-item">
              <ul class="it-list">
                <li class="item-list" v-for="(it, ind) in item.children" :key="ind">
                  <div v-if="it.name">{{ it.index }}. {{ it.name }}</div>
                  <img v-if="!it.subScore && !it.impairment && it.defectNum == 0" src="../../assets/report/success.png"
                    alt />
                  <div v-if="it.defectNum" @click="showInfo(it.name)">
                    <!-- <div v-if="it.impairment">{{ it.defectNum }}个问题，减值{{ it.impairment }}元</div> -->
                    <div v-if='it.subScore || it.impairment'>{{ it.defectNum }}个问题, <span v-if="it.subScore">扣{{ it.subScore }}分 </span> <span v-if="it.impairment">减值{{ it.impairment }}元</span></div>
                    <div v-else>{{ it.defectNum }}个问题，记录项</div>
                    <van-icon name="arrow" />
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </van-tab>
    </van-tabs>
    <van-popup v-model="show" round lock-scroll closeable position="bottom" :style="{ height: '80%' }">
      <div class="nav-titles">问题记录</div>
      <div class="box">
        <div class="txt">
          <swiper :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in list" :key="index">
              <div class="center">
                <van-image fit="cover" @click="onImageClick(list, index)" :src="item.img" />
                <ul class="problem">
                  <li class="p-tl">{{ item.name }}</li>
                  <li class="p-tlt">{{ item.index }}. {{ item.subName }} {{ item.num }}个问题</li>
                  <li class="p-tls" style="margin-bottom:10px" v-if="item.valueType == 1">
                    扣{{ item.paramValue }}分</li>
                  <li class="p-tls" style="margin-bottom:10px" v-if="item.valueType == 2">
                    减值{{ item.paramValue }}元</li>
                  <li class="p-tls" v-if="item.valueType == 1">扣分项</li>
                  <li class="p-tls" v-if="item.valueType == 2">减值项</li>
                  <li class="p-tls" v-if="item.valueType == 3">记录项</li>
                </ul>
                <div class="tips" v-if="item.valueType == 2">{{ item.remarks }},减值{{ item.paramValue }}元</div>
                <div class="tips" v-if="item.valueType == 1">{{ item.remarks }}</div>
                <div class="tips" v-if="item.valueType == 3">{{ item.remarks }}</div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </van-popup>
    <img class="back_img" @click="call_back" src="../../assets/backs.png" alt="">
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { ImagePreview } from "vant";
import { getTechAppraisal } from "@/utils/http.js";
export default {
  name: "",
  components: {
    swiper,
    swiperSlide,
  },
  props: [],
  data() {
    return {
      scro: true,
      index: 0,
      startX: null,
      startXs: null,
      surrentStartX: null,
      surrentStartXs: null,
      nextPage: 1,
      swiperOption: {
        loop: false,
        slideToClickedSlide: true,
        // 显示分页
        pagination: {
          // el: ".swiper-pagination",
          // clickable: true //允许分页点击跳转
        },
      },
      show: false,
      current: 1,
      active: 1,
      port: "",
      list: "",
    };
  },
  computed: {
    swiper: function () {
      return this.$refs.mySwiper.swiper;
    },
  },
  watch: {},
  created() { },
  mounted() {

    let obj = [
      { key: 1, children: [{ key: 1 }, { key: 2 }] },
      { key: 1, children: [{ key: 1 }, { key: 2 }] },
      { key: 1, children: [{ key: 1 }, { key: 2 }] },
      { key: 1, children: [{ key: 1 }, { key: 2 }] },
    ];
    console.log(obj, "-----");

    this.getReportInfo();
    //
  },
  methods: {
    formatThousand(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    call_back() { this.$router.go(-1); },
    async getReportInfo(id) {
    
      const { data: res } = await getTechAppraisal({
        taskId: this.$route.query.ids,
      });
      console.log(res.content)
      console.log('========')
      //   console.log(JSON.parse(res.content.technicalState));
      // res.content.technicalState = JSON.parse(res.content.technicalState);
      res.content.pop();
      for (let i = 0; i < res.content.length; i++) {
        let children = res.content[i].children;
        let index = 1;
        for (let j = 0; j < children.length; j++) {
          children[j].index = index;
          index++;
        }
        if (i > 0) {
          let prevChildren = res.content[i - 1].children;
          let lastObj = prevChildren[prevChildren.length - 1];
          index = lastObj.index + 1;
          for (let j = 0; j < children.length; j++) {
            children[j].index = index;
            index++;
          }
        }
      }
      this.port = res.content;
      this.$store.commit("setReport", res.content);
      let params = [];
      res.content.forEach((item) => {
        console.log(item,'=====')
        if(item.name == "骨架检查项目"){
          item.fullScore = item.fullScore.toFixed(2)
          item.score = item.score.toFixed(2)
        }
        item.children.forEach((ite) => {
          if (ite.impairment) {
            // console.log(ite,'=====')
            ite.contents.forEach(it=>{
              if(it.valueType == 2){
                it.paramValue = this.formatThousand(it.paramValue.toFixed(2))
              }
            })
            ite.impairment = this.formatThousand(ite.impairment.toFixed(2))
          }
          if (ite.defectNum) {
            ite.contents.forEach((it) => {
              it.images.forEach((i) => {
                params.push({
                  name: item.name,
                  subName: ite.name,
                  index: ite.sortId,
                  valueType: it.valueType,
                  paramValue: it.paramValue,
                  remarks: it.remarks,
                  img: i,
                  index: ite.index,
                  num: ite.defectNum,
                });
              });
            });
          }
        });
      });
      // console.log(params);
      this.list = [...new Set(params)];

      // console.log(this.list);
    },
    // 点击图片
    onImageClick(list, index) {
      let arr = [];
      list.forEach((item) => {
        arr.push(item.img);
      });
      ImagePreview({
        images: arr,
        startPosition: index,
      });
    },
    showInfo(data, key) {
      console.log(data);
      let list = [];
      this.list.forEach((item, index) => {
        if (item.subName == data) {
          list.push(index);
        }
      });
      this.show = true;
      console.log(this.list);
      setTimeout(() => {
        this.swiper.slideTo(list[0], 0, false);
      }, 100);
    },
  },
};
</script>
<style lang='less'  scoped>
.container {
  background: #f8f8f8;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-bottom: 20px;
}

.content {
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 12px;
}

.name {
  font-size: 19px;
  padding-top: 15px;
}

.changeNumber {
  display: flex;
  color: #d7d7d7;
  font-size: 13px;
  font-weight: 400;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  margin-top: 10px;
  justify-content: space-around;
}

.changeNumber>li {
  width: 108px;
  text-align: center;
}

.changeNumber>li>div:nth-of-type(1) {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  margin-bottom: 6px;
}

.changeNumber>li {
  width: 108px;
  text-align: center;
}

.changeNumber>.lines {
  width: 0px;
  height: 35px;
  border-left: 1px solid #e6e6e6;
}

.c-p-n {
  /* width: 100%; */
  height: 80px;
  background-image: url("../../assets/report/number.png");
  background-size: 100% 100%;
  margin: 15px 0;
}

.c-p-n>li>div:nth-of-type(1) {
  color: #ffffff;
  margin-bottom: 10px;
}

.c-p-n>.lines {
  border-left: 1px solid #505050;
}

.nav-title {
  font-size: 17px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
}

.nav-item>img {
  width: 334px;
  height: 180px;
  border-radius: 5px;
}

/deep/ .van-tabs__line {
  width: 20px !important;
  height: 10px !important;
  background-color: transparent !important;
  background: url("https://ftp.bmp.ovh/imgs/2021/05/503330710675b702.png") center center;
  background-size: 100% 100%;
}

/deep/ .van-tab {
  font-size: 18px;
}

/deep/ .van-tabs--line .van-tabs__wrap {
  height: 50px;
}

.it-list {
  padding-bottom: 10px;
}

.item-list {
  display: flex;
  justify-content: space-between;
  min-height: 54px;
  align-items: center;
  font-size: 16px;
}

.item-list>img {
  width: 16px;
  height: 16px;
}

.item-list>div:nth-of-type(2) {
  display: flex;
  color: #999999;
  align-items: center;
}

.item-list>div:nth-of-type(1) {
  width: 200px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 20px;
  padding: 20px 0;
  // display: flex;
  // color: #999999;
}

.item-list>div:nth-of-type(2)>div {
  margin-right: 2px;
  font-size: 14px;
  color: #ff3b3b;
  text-align: right;
  line-height: 18px;
}

.center {
  width: 345px;
  margin: 0 auto;
  padding-top: 10px;
  color: #333333;
  padding-bottom: 40px;
}

/deep/ .center>.van-image {
  width: 345px;
  height: 259px;
  margin: 0 auto;
}

/deep/ .center>.van-image {
  width: 345px;
  height: 259px;
  border-radius: 5px;
}

.money {
  height: 20px;
  line-height: 20px;
  font-size: 14px !important;
}

.tips {
  font-size: 14px;
  color: #999999;
  margin-top: 15px;
  line-height: 18px;
}

.nav-titles {
  text-align: center;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f3f3f3;
}

/deep/ .van-popup__close-icon--top-right {
  top: 20px;
  right: 20px;
}

.problem {
  min-height: 30px;
  margin-top: 10px;
  font-size: 18px;
}

.problems {
  justify-content: start;
  font-size: 15px;
}

.problems>li:nth-of-type(1) {
  line-height: 30px;
}

.problem>li:nth-of-type(2) {
  margin: 10px 0;
  font-size: 15px;
}

/deep/ .van-tab {
  font-size: 16px;
}

/deep/.van-tab--active>.van-tab__text {
  font-size: 19px;
}

.it-list>.item-list {
  border-top: 1px solid #ebebeb;
}

.p-tl {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
}

.p-tlt {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 30px;
  color: #333333;
}

.p-tls {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.tab-class {
  flex: none !important;
}

.back_img {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 150px;
  z-index: 99999999;
}

</style>